import React, { useState, useRef } from 'react';
import '../../../styles/AskQuestion.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { toast } from "react-toastify";
import '../../../styles/Loading.css';
import NotificationService from '../../../services/NotificationService';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function CreateNotification() {

    const notificationService = new NotificationService();

    const fileInputRef = useRef(null);
    const [loading, setLoading] = useState(false); // Yüklenme durumu

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [link, setLink] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);


    const handleFileUpload = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleFileRemove = () => {
        setSelectedFile(null);

        fileInputRef.current.value = null;
    };


    const handleClick = () => {
        // Butona tıklandığında dosya seçme penceresini aç
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleSubmit = () => {

        setLoading(true)
        let formData = new FormData();
        formData.append('title', title);
        formData.append("content", content);
        formData.append('link', link);
        formData.append('file', selectedFile);
     
        notificationService.saveNotification(formData).then(result => {

            if (result.data.success === true) {

                setLoading(false)

                toast.success("Bildiriminiz başarıyla sisteme kaydedildi.");

                setTitle('')
                setContent('');
                setLink('')
                setSelectedFile(null);
            }
        }).catch(error => {

            setLoading(false)
            toast.error(error.response.data.data.message);

        })

    }

    return (
        <div className='ask-us-question-container'>

            {loading === true &&
                <div className="loading-container">
                    <div className="loading-icon"></div> {/* Loading ikonu */}
                    <p>Veriler kaydediliyor...</p>
                </div>
            }

            {loading === false && (

                <><>

                    <textarea
                        type="text"
                        placeholder="Bildirim başlığınızı yazınız"
                        className='ask-us-title-input'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)} />


                    <textarea
                        type="text"
                        placeholder="İçeriği açıklayınız"
                        className='ask-us-body-input'
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                    />

                    <textarea
                        type="text"
                        placeholder="Link ekleyiniz"
                        className='ask-us-title-input'
                        value={link}
                        rows={1}
                        onChange={(e) => setLink(e.target.value)}
                        />


                    {selectedFile && (
                        <div className="selected-files-container">
                            <span className="image-name">{selectedFile.name}</span>
                            <FontAwesomeIcon
                                icon={faTimes}
                                className="remove-icon"
                                onClick={() => handleFileRemove()} />
                        </div>
                    )}
                </>
                    <div className='ask-usb-btn-container'>

                        <button className="ask-us-img-submit-button" onClick={handleClick} >
                            Dosya Ekle
                            <input
                                type="file"
                                accept=".zip,.rar"
                                onChange={handleFileUpload}
                                ref={fileInputRef}
                                style={{ display: 'none' }} // Input'u gizle
                            />
                        </button>

                        <button className="ask-us-submit-button" style={{ backgroundColor: "green" }}
                        onClick={handleSubmit} >
                            Kaydet
                        </button>
                    </div></>
            )}



        </div>
    )
}
