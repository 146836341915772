import React, { useState } from 'react';
import CreateNotification from './CreateNotification';
import ListNotifications from './ListNotifications';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../styles/AskUsHome.css';

export default function ManageNotificationHome() {

    const [selectedMenu, setSelectedMenu] = useState('Bildirim Oluştur');

    const renderContent = () => {
        switch (selectedMenu) {
            case 'Bildirim Oluştur':
                return <div><CreateNotification /></div>;
            case 'Tüm Bildirimler':
                return <div><ListNotifications /></div>;
            default:
                return <div></div>;
        }
    };

    return (
        <div className="ask-us-bg-img d-flex">

            {/* Menü kısmı */}
            <div className="p-3 ask-us-menu-container">

                <div className='ask-us-menu' >
                    <ul className="nav flex-column">
                        <li className="nav-item">

                            <span className="icon-wrapper">
                            <i class="fa-regular fa-bell notification-icon"></i>
                            </span>
                            <button
                                className={`nav-link btn ${selectedMenu === 'Bildirim Oluştur' ? 'active' : 'transparent'} ask-us-menu-text`}
                                onClick={() => setSelectedMenu('Bildirim Oluştur')}
                            >
                                Bildirim Oluştur
                            </button>
                        </li>
      
                        <li className="nav-item mt-2">

                            <span className="icon-wrapper">
                                <i className="menu-icon frequently-ask-question-icon me-3" />
                            </span>
                            <button
                                className={`nav-link btn ${selectedMenu === 'Tüm Bildirimler' ? 'active' : 'transparent'} ask-us-menu-text`}
                                onClick={() => setSelectedMenu('Tüm Bildirimler')}
                            >
                                Tüm Bildirimler
                            </button>
                        </li>
                    </ul>
                </div>

            </div>

            {/* İçerik kısmı */}
            <div className="ask-us-page">
                <div className="ask-us-page-card">
                    {renderContent()}
                </div>
            </div>
        </div>
    )
}
