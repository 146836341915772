import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from '../pages/Homepage';
import SignUpPage from '../pages/SignUpPage';
import SignInPage from '../pages/SignInPage';
import Footer from '../layouts/Footer'
import Navbar from '../layouts/Navbar';
import Organization from '../pages/Organization';
import Announcement from '../pages/Announcement';
import ArchiveHome2024 from '../pages/archive/ArchiveHome2024';
import ArchiveDetail2024 from '../pages/archive/ArchiveDetail2024';
import Communication from '../pages/Communication';
import ManageAnnouncement from '../pages/admin/ManageAnnouncement';
import ForgotPassword from '../pages/ForgotPassword';
import TechnicalAdvisorsList from '../pages/TechnicalAdvisorsList';
import OrganizationExecutiveList from '../pages/OrganizationExecutiveList';
import ManageOrganizationExecutiveList from '../pages/admin/ManageOrganizationExecutiveList';
import ManageTechnicalAdvisorsList from '../pages/admin/ManageTechnicalAdvisorsList';
import ApplyExam from '../pages/ApplyExam';
import StartExam from '../pages/StartExam';
import ManagePresentation from '../pages/admin/ManagePresentation';
import ArchiveHome2023 from '../pages/archive/ArchiveHome2023';
import ArchiveDetail2023 from '../pages/archive/ArchiveDetail2023';
import ExamResult from '../pages/admin/ExamResult';
import AskUsHome from '../pages/askUs/AskUsHome';
import ManageAskUs from '../pages/admin/ManageAskUs';
import Accommodation from '../pages/Accommodation';
import ManageAccommodation from '../pages/admin/ManageAccommodation';
import TeamRegistration from '../pages/TeamRegistration';
import Team from '../pages/Team';
import ListAllUsers from '../pages/admin/ListAllUsers';
import ManageInstitution from '../pages/admin/ManageInstitution';
import YourNotifications from '../pages/YourNotifications';
import ManageNotificationHome from '../pages/admin/ManageNotification/ManageNotificationHome';

export default function HomeDashboard() {

  const [clickedButton, setClickedButton] = useState(null);

  return (

    <div>

      <div style={{ marginRight: "0", marginLeft: "0" }}>
        <div className="col-12">
          <Navbar />
        </div>
      </div>

      <div style={{ minHeight: "100vh * 0.75" }}>
        <div className="col-12" >

          <Routes>
            <Route exact path="/" element={<Homepage />} />
            <Route exact path="/signUp" element={<SignUpPage />} />
            <Route exact path="/signIn" element={<SignInPage />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/organization" element={<Organization />} />
            <Route exact path="/announcement" element={<Announcement />} />
            <Route exact path="/archiveHome2024" element={<ArchiveHome2024 setClickedButton={setClickedButton} />} />
            <Route exact path="/archiveDetail2024" element={<ArchiveDetail2024 clickedButton={clickedButton} />} />
            <Route exact path="/contact" element={<Communication />} />
            <Route exact path="/manageAnnouncement" element={<ManageAnnouncement />} />
            <Route exact path="/technic-advisor-list" element={<TechnicalAdvisorsList />} />
            <Route exact path="/organization-executive-list" element={<OrganizationExecutiveList />} />
            <Route exact path="/manage-technic-advisor" element={<ManageTechnicalAdvisorsList />} />
            <Route exact path="/manage-organization-executive" element={<ManageOrganizationExecutiveList />} />
            <Route exact path="/user-home" element={<ApplyExam />} />
            <Route exact path="/start-exam" element={<StartExam />} />
            <Route exact path="/exam-result" element={<ExamResult />} />
            <Route exact path="/managePresentation" element={<ManagePresentation />} />
            <Route exact path="/archiveHome2023" element={<ArchiveHome2023 setClickedButton={setClickedButton} />} />
            <Route exact path="/archiveDetail2023" element={<ArchiveDetail2023 clickedButton={clickedButton} />} />
            <Route exact path="/ask-us-home" element={<AskUsHome />} />
            <Route exact path="/manageAskUs" element={<ManageAskUs />} />
            <Route exact path="/accomodation" element={<Accommodation />} />
            <Route exact path="/manage-accomodation" element={<ManageAccommodation />} />
            <Route exact path="/teamRegister" element={<TeamRegistration />} />
            <Route exact path="/my-team" element={<Team />} />
            <Route exact path="/list-all-users" element={<ListAllUsers />} />
            <Route exact path="/manage-institutions" element={<ManageInstitution />} />
            <Route exact path="/your-notifications" element={<YourNotifications />} />            
            <Route exact path="/manage-notifications" element={<ManageNotificationHome />} />            

          </Routes>

        </div>
      </div>

      <div>
        <div className="col-12" style={{ position: "relative", marginRight: "0", marginLeft: "0", bottom: "0", width: "100%" }}>
          <Footer />
        </div>
      </div>

    </div>
  )
}
